import React from "react"
import {SceneProps} from "../App";
import styled from "styled-components";
import LABELS from "../labels";
import VIDEO from "../assets/plansza_4/text_in.webm";

import Level_1 from "../assets/plansza_4/webm/wanny_malo.mp4"
import Level_2 from "../assets/plansza_4/webm/wanny_srednio.mp4"
import Level_3 from "../assets/plansza_4/webm/wanny_srednio_duzo.mp4"
import Level_4 from "../assets/plansza_4/webm/wanny_duzo.mp4"
import Level_5 from "../assets/plansza_4/webm/wanny_bardzo_duzo.mp4"
import {INTRO} from "../routes";
import $ from "jquery"

const Header = styled.div`
    font-size: 300%;
    // margin-top: 23%;
    margin-top: 70%;
    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    color: white;
    z-index: 130;
`

const Video = styled.video`
    z-index: 1;
    position: absolute;
    width: 1080px;
    height 1920px;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
`

const HumanText = styled.h1`
    font-size: 250%;
    margin-top: 43%;
    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    color: white;
    z-index: 10;
    width: 60%;
`

const BathtubText = styled.h1`
    font-size: 240%;
    margin-top: 73%;
    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    color: white;
    width: 50%;
    z-index: 9999;
`

const BathVideo = styled.video`
    width: 100%;
    margin-top: -58%;
    position: absolute;
    z-index: 1;
`

const BackBtn = styled.img`
    margin-top: 60%;
    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    color: white;
    z-index: 10;
`

const FactContainer = styled.div`
    margin-top: 20%;
    min-height: 20em;
    // display: table-cell;
    vertical-align: middle;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 99;
`
const Fact = styled.div`
    color: white;
    z-index: 10;
    width: 70%;
    background-color: #5DB6D3;
    border-radius: 50px;
    font-size: 160%;
`

const Counter = styled.h1`
    font-size: 800%;
    color: white;
    position: absolute;
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    z-index: 99
`

const LitersPerDay = 150; // human liters to live for a day
const LitersPerBathtub = 200;

const Summary: React.FC<SceneProps> = ({changeLang, changePage, lang, page}) => {

    const getFact = () => {
        let facts;
        if (page["data"]["source"] == "cloths") {
            facts = LABELS[lang]["facts"]["cloths"];
        } else {
            facts = LABELS[lang]["facts"]["food"];
        }

        return facts[Math.floor(Math.random() * facts.length)];
    }
    const fact = getFact();

    const calcTime = () => {
        const days = parseInt(page["data"]["waterSum"]) / LitersPerDay

        return Math.round(days);
    }

    const calcTubes = () => {
        return Math.round(page["data"]["waterSum"] / LitersPerBathtub)
    }

    const formatWater = (num) => {
        const strNum = num.toString()

        if (strNum.length > 3) {
            return `${strNum.substring(0, strNum.length - 3)} ${strNum.substring(strNum.length - 3)}`
        } else {
            return strNum
        }
    }

    const getVideo = () => {
        const tubes = calcTubes();

        if (tubes < 10) {
            return Level_1
        } else if (tubes < 30) {
            return Level_2
        } else if (tubes < 60) {
            return Level_3
        } else if (tubes < 100) {
            return Level_4
        } else {
            return Level_5
        }
    }

    $(document).ready(() => {
        const textVideo = $("[data-text-video]");
        const literText = $("[data-liters-text]");
        const canLiveText = $("[data-can-live-text]");
        const bathTubesText = $("[data-bathtubes-text]");
        const bathTubesContainer = $("[data-bathtubes-container]");
        const bathTubeVideo = $("[data-bathtube-video]");
        const bathTubesCounter = $("[data-counter]");
        const factsContainer = $("[data-facts-container]");
        const back = $("[data-back]");

        const countDown = () => {
            let counted = 0;
            const max = calcTubes();

            const countDownInterval = setInterval(() => {
                if (counted === max) {
                    clearInterval(countDownInterval)
                }else{
                    console.log(`Max: ${max} , counted ${counted}`)
                    counted = counted + 1;
                    bathTubesCounter.text(`${counted} x`)
                }
            }, Math.round(2.5 * 1000 / max))
        }

        literText.animate({top: "-26%"}, 1000, "swing", () => {
            textVideo.show();
            const video: any = textVideo[0]
            video.play();
            canLiveText.fadeIn("slow")
        });

        textVideo[0].onended = () => {
            bathTubesText.fadeIn("slow", () => {
                bathTubesCounter.css("display", "flex");
                countDown();

                bathTubesContainer.css("display", "flex");
                const video: any = bathTubeVideo[0];
                video.play();
            })
        }

        bathTubeVideo[0].onended = () => {
            factsContainer
                .css("display", "flex")
                .hide()
                .fadeIn(() => {
                    back.fadeIn("slow");
                });
        }
    })

    return (
        <div className="scene">
            <Video data-text-video src={VIDEO} muted/>
            <Header data-liters-text
                    className={"text-center"}>
                <strong>{formatWater(page["data"]["waterSum"])} {LABELS[lang]["liters2"]}</strong></Header>
            <HumanText data-can-live-text
                       className={"text-center"}>{LABELS[lang]["canLive"].replace("XXX", calcTime())}</HumanText>
            <BathtubText data-bathtubes-text className={"text-center"}>{LABELS[lang]["bathtubs"]}</BathtubText>

            <div data-bathtubes-container className={"row col-12"} style={{"marginTop": "100%"}}>
                <div className={"col-6"}>
                    <Counter data-counter className={"text-right"}>0 x</Counter>
                </div>
                <div className={"col-6"} style={{"zIndex": 99}}>
                    <BathVideo data-bathtube-video src={getVideo()} muted></BathVideo>
                </div>
            </div>
            <FactContainer data-facts-container className={"align-items-center justify-content-center"}>
                <Fact className={ fact.length > 55 ? "text-justify p-5 mx-auto" : "text-center p-5 mx-auto"}>{fact}</Fact>
            </FactContainer>
            <BackBtn data-back src={LABELS[lang]["backStart"]} onClick={() => {
                changePage({name: INTRO})
            }}/>
        </div>
    )
}

export default Summary