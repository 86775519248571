import React, {useState} from "react"
import {SceneProps} from "../App";
import styled from "styled-components"
import LABELS from "../labels";

import adidasy from "../assets/plansza_2/cloths/adidasy.png"
import bokserki from "../assets/plansza_2/cloths/bokserki.png"
import buty_skorzane from "../assets/plansza_2/cloths/buty_skorzane.png"
import czapka from "../assets/plansza_2/cloths/czapka.png"
import koszula from "../assets/plansza_2/cloths/koszula.png"
import kurtka from "../assets/plansza_2/cloths/kurtka.png"
import rekawiczki from "../assets/plansza_2/cloths/rekawiczki.png"
import skarpy from "../assets/plansza_2/cloths/skarpy.png"
import spodnie from "../assets/plansza_2/cloths/spodnie.png"
import sukienka from "../assets/plansza_2/cloths/sukienka.png"
import video from "../assets/plansza_2/video.webm"

import adidasyH from "../assets/plansza_2/cloths/adidasy_highlited.png"
import bokserkiH from "../assets/plansza_2/cloths/bokserki_highlited.png"
import buty_skorzaneH from "../assets/plansza_2/cloths/buty_skorzane_highlited.png"
import czapkaH from "../assets/plansza_2/cloths/czapka_highlited.png"
import koszulaH from "../assets/plansza_2/cloths/koszula_highlited.png"
import kurtkaH from "../assets/plansza_2/cloths/kurtka_highlited.png"
import rekawiczkiH from "../assets/plansza_2/cloths/rekawiczki_highlited.png"
import skarpyH from "../assets/plansza_2/cloths/skarpy_highlited.png"
import spodnieH from "../assets/plansza_2/cloths/spodnie_highlited.png"
import sukienkaH from "../assets/plansza_2/cloths/sukienka_highlited.png"

import RecipStart from "../assets/plansza_2/recipt_start.png"
import RecipEnd from "../assets/plansza_2/recipt_end.png"
import {INTRO, SUMMARY} from "../routes";
import RecipPrinter from "./Recip";

interface Clothing {
    name: string;
    img: string;
    imgH: string;
    position: any;
    water: number;
    label: any;
}

/**
 * - Skórzane buty – 14 000L
 - Buty sportowe – 8500L
 - Jeansy – 8000L
 - T-shirt – 2700L
 - Koszula – 4000L
 - Sukienka - 6000L
 - Kurtka – 14 000L
 - Czapka zimowa – 1000 L
 - Skórzane rękawiczki – 8000L
 - Bokserki – 250 L
 - Skarpetki – 500 L

 */
const clothingSet: Clothing[] = [
    {
        "name": "adidasy",
        "img": adidasy,
        "imgH": adidasyH,
        "position": {
            "marginTop": "15%",
            "left": "5%"
        },
        "water": 8500,
        "label": {
            "pl": "Adidasy",
            "eng": "Sport shoes",
        }
    },
    {
        "name": "bokserki",
        "img": bokserki,
        "imgH": bokserkiH,
        "position": {
            "marginTop": "0%",
            "left": "47%",
        },
        "water": 250,
        "label": {
            "pl": "Bokserki",
            "eng": "Boxer shorts",
        }
    }, {
        "name": "buty_skorzane",
        "img": buty_skorzane,
        "imgH": buty_skorzaneH,
        "position": {
            "marginTop": "40%",
            "left": "5%"
        },
        "water": 14000,
        "label": {
            "pl": "Buty skórzane",
            "eng": "Leather shoes",
        }
    }, {
        "name": "czapka",
        "img": czapka,
        "imgH": czapkaH,
        "position": {
            "marginTop": "34%",
            "left": "50%",
        },
        "water": 1000,
        "label": {
            "pl": "Czapka",
            "eng": "Cap",
        }
    }, {
        "name": "koszula",
        "img": koszula,
        "imgH": koszulaH,
        "position": {
            "marginTop": "61%",
            "left": "15%",
            "width": "34%"
        },
        "water": 2700,
        "label": {
            "pl": "Koszula",
            "eng": "Shirt",
        }
    }, {
        "name": "kurtka",
        "img": kurtka,
        "imgH": kurtkaH,
        "position": {
            "marginTop": "59%",
            "left": "50%",
        },
        "water": 14000,
        "label": {
            "pl": "Kurtka",
            "eng": "Jacket",
        }
    }, {
        "name": "rekawiczki",
        "img": rekawiczki,
        "imgH": rekawiczkiH,
        "position": {
            "marginTop": "89%",
            "left": "15%",
        },
        "water": 8000,
        "label": {
            "pl": "Rękawiczki",
            "eng": "Gloves",
        }
    }, {
        "name": "skarpetki",
        "img": skarpy,
        "imgH": skarpyH,
        "position": {
            "marginTop": "98%",
            "left": "43%",
        },
        "water": 500,
        "label": {
            "pl": "Skarpetki",
            "eng": "Socks",
        }
    }, {
        "name": "spodnie",
        "img": spodnie,
        "imgH": spodnieH,
        "position": {
            "marginTop": "124%",
            "left": "6%",
        },
        "water": 8000,
        "label": {
            "pl": "Spodnie",
            "eng": "Jeans",
        }
    }, {
        "name": "sukienka",
        "img": sukienka,
        "imgH": sukienkaH,
        "position": {
            "marginTop": "117%",
            "left": "52%",
        },
        "water": 6000,
        "label": {
            "pl": "Sukienka",
            "eng": "Dress",
        }
    }
]

const Video = styled.video`
    width: 100%;
    position: absolute;
`

const ClothItem = styled.img`
    position: absolute;
    transform: scale(0.6, 0.6);
`

const Recip = styled.div`
    background-color: none;
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 188px;
    top: 160px;
`

const RecipBody = styled.div`
    background-color: white;
    margin: 0 0 0 0;
    width: 100%;
    padding: 0 0 0 0;
`

const NameRow = styled.td`
  width": "60%;
`
const LitersRow = styled.td`
  word-break: break-all;
  width": "40%;
`

const HeaderVideo = styled.video`
    position: absolute;
    margin-top: 5%;
`

const Cloths: React.FC<SceneProps> = ({changeLang, changePage, lang}) => {
    const [picked, setPicked] = useState({});

    const isHighlighted = (cloth: Clothing) => {
        if (cloth.name in picked) {
            return true;
        } else {
            return false;
        }
    }

    const getByName = (name: string) => {
        let chosen;

        clothingSet.forEach((item) => {
            if (item.name == name) {
                chosen = item;
            }
        })
        return chosen;
    }

    const switchItem = (event) => {
        const name = event.target.getAttribute('data-name')
        const active = (event.target.getAttribute('data-highlighted') == "true")

        let changed = {...picked};

        if (active) {
            delete changed[name]
        } else {
            changed[name] = getByName(name)
        }
        setPicked(changed)
    }

    const waterSum = () => {
        let sum = 0;

        Object.keys(picked).forEach((key) => {
            sum = sum + picked[key].water;
        })

        return sum;
    }

    return (
        <div className="scene">
            <HeaderVideo autoPlay muted src={LABELS[lang]["clothHeader"]} />
            <div className={"col-12 row"} style={{"position": "absolute", "marginTop": "35%"}}>
                <div className={"col-7"}>
                    <Video autoPlay loop muted src={video}/>
                    {clothingSet.map((item) => {
                        return <ClothItem key={item.name}
                                          data-name={item.name}
                                          data-highlighted={isHighlighted(item)}
                                          src={isHighlighted(item) ? item.imgH : item.img} style={item.position}
                                          onClick={switchItem}/>
                    })}
                </div>
                <RecipPrinter lang={lang} picked={picked} waterSum={waterSum()} />
            </div>
            <div className={"col-12 text-center"} style={{"position": "absolute", "marginTop": "155%"}}>
                <img className={"max-auto p-4"} src={LABELS[lang]["back"]} onClick={() => {
                    changePage({"name": INTRO})
                }}/>
                <img className={"max-auto p-4"} src={LABELS[lang]["cash"]} onClick={() => {
                    changePage({
                        "name": SUMMARY,
                        "data": {
                            "waterSum": waterSum(),
                            "source": "cloths"
                        }
                    })
                }}/>
            </div>
        </div>
    )
}

export default Cloths