import React, {useState} from "react"
import {SceneProps} from "../App";
import styled from "styled-components"
import LABELS from "../labels";
import {INTRO, SUMMARY} from "../routes";
import FoodImg from "../assets/plansza_3/regal.png";

import banan from "../assets/plansza_3/food/banan.png"
import chleb from "../assets/plansza_3/food/chleb.png"
import cukier from "../assets/plansza_3/food/cukier.png"
import czekolada from "../assets/plansza_3/food/czekolada.png"
import jablko from "../assets/plansza_3/food/jablko.png"
import kawa from "../assets/plansza_3/food/kawa.png"
import kukurydza from "../assets/plansza_3/food/kukurydza.png"
import kurczak from "../assets/plansza_3/food/kurczak.png"
import makaron from "../assets/plansza_3/food/makaron.png"
import maslo from "../assets/plansza_3/food/maslo.png"
import mleko from "../assets/plansza_3/food/mleko.png"
import ryz from "../assets/plansza_3/food/ryz.png"
import salata from "../assets/plansza_3/food/salata.png"
import ser from "../assets/plansza_3/food/ser.png"
import wieprzowina from "../assets/plansza_3/food/wieprzowina.png"
import wino from "../assets/plansza_3/food/wino.png"
import woda from "../assets/plansza_3/food/woda.png"
import wolowina from "../assets/plansza_3/food/wolowina.png"

import bananH from "../assets/plansza_3/food/banan_highlited.png"
import chlebH from "../assets/plansza_3/food/chleb_highlited.png"
import cukierH from "../assets/plansza_3/food/cukier_highlited.png"
import czekoladaH from "../assets/plansza_3/food/czekolada_highlited.png"
import jablkoH from "../assets/plansza_3/food/jablko_highlited.png"
import kawaH from "../assets/plansza_3/food/kawa_highlited.png"
import kukurydzaH from "../assets/plansza_3/food/kukurydza_highlited.png"
import kurczakH from "../assets/plansza_3/food/kurczak_highlited.png"
import makaronH from "../assets/plansza_3/food/makaron_highlited.png"
import masloH from "../assets/plansza_3/food/maslo_highlited.png"
import mlekoH from "../assets/plansza_3/food/mleko_highlited.png"
import ryzH from "../assets/plansza_3/food/ryz_highlited.png"
import salataH from "../assets/plansza_3/food/salata_highlited.png"
import serH from "../assets/plansza_3/food/ser_highlited.png"
import wieprzowinaH from "../assets/plansza_3/food/wieprzowina_highlited.png"
import winoH from "../assets/plansza_3/food/wino_highlited.png"
import wodaH from "../assets/plansza_3/food/woda_highlited.png"
import wolowinaH from "../assets/plansza_3/food/wolowina_highlited.png"
import RecipPrinter from "./Recip";

interface Meal {
    name: string;
    img: string;
    imgH: string;
    position: any;
    water: number;
    label: any;
}

const mealSet: Meal[] = [
    {
        "name": "banan",
        "img": banan,
        "imgH": bananH,
        "position": {
            "marginTop": "-15px",
            "left": "-5%"
        },
        "water": 860,
        "label": {
            "pl": "Banan 1 kg",
            "eng": "Banana 1 kg",
        }
    }, {
        "name": "chleb",
        "img": chleb,
        "imgH": chlebH,
        "position": {
            "marginTop": "5px",

            "left": "17%"
        },
        "water": 650,
        "label": {
            "pl": "Chleb 1 szt.",
            "eng": "Bread 1 pc.",
        }
    }, {
        "name": "cukier",
        "img": cukier,
        "imgH": cukierH,
        "position": {
            "marginTop": "15px",

            "left": "38%"
        },
        "water": 1500,
        "label": {
            "pl": "Cukier 1 kg",
            "eng": "Sugar 1 kg",
        }
    }, {
        "name": "czekolada",
        "img": czekolada,
        "imgH": czekoladaH,
        "position": {
            "marginTop": "5px",

            "left": "60%"
        },
        "water": 2000,
        "label": {
            "pl": "Czekolada 100 g",
            "eng": "Chocolate 100 g",
        }
    }, {
        "name": "jablko",
        "img": jablko,
        "imgH": jablkoH,
        "position": {
            "marginTop": "41%",
            "left": "0%"
        },
        "water": 700,
        "label": {
            "pl": "jabłka 1 kg",
            "eng": "Apple 1 kg",
        }
    }, {
        "name": "kawa",
        "img": kawa,
        "imgH": kawaH,
        "position": {
            "marginTop": "41%",
            "left": "7%"
        },
        "water": 17500,
        "label": {
            "pl": "kawa 1 kg",
            "eng": "Coffee 1 kg",
        }
    }, {
        "name": "kukurydza",
        "img": kukurydza,
        "imgH": kukurydzaH,
        "position": {
            "marginTop": "35%",
            "left": "29%"
        },
        "water": 900,
        "label": {
            "pl": "kukurydza 1 kg",
            "eng": "Corn 1 kg",
        }
    }, {
        "name": "kurczak",
        "img": kurczak,
        "imgH": kurczakH,
        "position": {
            "marginTop": "32%",
            "left": "41%"
        },
        "water": 3600,
        "label": {
            "pl": "kurczak 1 kg",
            "eng": "Chicken 1 kg",
        }
    }, {
        "name": "makaron",
        "img": makaron,
        "imgH": makaronH,
        "position": {
            "marginTop": "30%",
            "left": "55%"
        },
        "water": 2200,
        "label": {
            "pl": "makaron 1 kg",
            "eng": "Pasta 1 kg",
        }
 }, {
        "name": "maslo",
        "img": maslo,
        "imgH": masloH,
        "position": {
            "marginTop": "59%",
            "left": "-9%"
        },
        "water": 1100,
        "label": {
            "pl": "masło 200 g",
            "eng": "Butter 200 g",
        }
    }, {
        "name": "mleko",
        "img": mleko,
        "imgH": mlekoH,
        "position": {
            "marginTop": "60%",
            "left": "46%"
        },
        "water": 1000,
        "label": {
            "pl": "mleko 1 L",
            "eng": "Milk 1 L",
        }
    }, {
        "name": "ryz",
        "img": ryz,
        "imgH": ryzH,
        "position": {
            "marginTop": "70%",
            "left": "15%"
        },
        "water": 2300,
        "label": {
            "pl": "ryż 1 kg",
            "eng": "Rice 1 kg",
        }
    }, {
        "name": "salata",
        "img": salata,
        "imgH": salataH,
        "position": {
            "marginTop": "63%",
            "left": "40%"
        },
        "water": 130,
        "label": {
            "pl": "salata 1 kg",
            "eng": "Lettuce 1 kg",
        }
    }, {
        "name": "ser",
        "img": ser,
        "imgH": serH,
        "position": {
            "marginTop": "100%",
            "left": "2%"
        },
        "water": 5000,
        "label": {
            "pl": "ser 1 kg",
            "eng": "Cheese 1 kg",
        }
    }, {
        "name": "wieprzowina",
        "img": wieprzowina,
        "imgH": wieprzowinaH,
        "position": {
            "marginTop": "93%",
            "left": "15%"
        },
        "water": 5000,
        "label": {
            "pl": "wieprzowina 1 kg",
            "eng": "Pork 1 kg",
        }
    }, {
        "name": "wino",
        "img": wino,
        "imgH": winoH,
        "position": {
            "marginTop": "88%",
            "left": "34%"
        },
        "water": 700,
        "label": {
            "pl": "wino 1 L",
            "eng": "Wine 1 L",
        }
    }, {
        "name": "woda",
        "img": woda,
        "imgH": wodaH,
        "position": {
            "marginTop": "87%",
            "left": "73%"
        },
        "water": 5,
        "label": {
            "pl": "woda 1 L",
            "eng": "Bottled water 1 L",
        }
    },
]

const Img = styled.img`
    width: 100%;
    position: absolute;
`

const ClothItem = styled.img`
    position: absolute;
    transform: scale(0.5, 0.5);
`

const HeaderVideo = styled.video`
    position: absolute;
    margin-top: 5%;
`

const Food: React.FC<SceneProps> = ({changeLang, changePage, lang}) => {
    const [picked, setPicked] = useState({});

    const isHighlighted = (meal: Meal) => {
        if (meal.name in picked) {
            return true;
        } else {
            return false;
        }
    }

    const getByName = (name: string) => {
        let chosen;

        mealSet.forEach((item) => {
            if (item.name == name) {
                chosen = item;
            }
        })
        return chosen;
    }

    const switchItem = (event) => {
        const name = event.target.getAttribute('data-name')
        const active = (event.target.getAttribute('data-highlighted') == "true")

        let changed = {...picked};

        if (active) {
            delete changed[name]
        } else {
            changed[name] = getByName(name)
        }
        setPicked(changed)
    }

    const waterSum = () => {
        let sum = 0;

        Object.keys(picked).forEach((key) => {
            sum = sum + picked[key].water;
        })

        return sum;
    }

    return (
        <div className="scene">
            <HeaderVideo autoPlay muted src={LABELS[lang]["foodHeader"]} />
            <div className={"col-12 row"} style={{"position": "absolute", "marginTop": "35%"}}>
                <div className={"col-7"}>
                    <Img src={FoodImg}/>
                    {mealSet.map((item) => {
                        return <ClothItem key={item.name}
                                          data-name={item.name}
                                          data-highlighted={isHighlighted(item)}
                                          src={isHighlighted(item) ? item.imgH : item.img} style={item.position}
                                          onClick={switchItem}/>
                    })}
                </div>
                <RecipPrinter lang={lang} picked={picked} waterSum={waterSum()} />
            </div>
            <div className={"col-12 text-center"} style={{"position": "absolute", "marginTop": "155%"}}>
                <img className={"max-auto p-4"} src={LABELS[lang]["back"]} onClick={() => {
                    changePage({"name": INTRO})
                }}/>
                <img className={"max-auto p-4"} src={LABELS[lang]["cash"]} onClick={() => {
                    changePage({
                        "name": SUMMARY,
                        "data": {
                            "waterSum": waterSum(),
                            "source": "food"
                        }
                    })
                }}/>
            </div>
        </div>
    )
}

export default Food