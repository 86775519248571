import React, {useContext, useState} from 'react';
import './App.css';
import {INTRO, FOOD, CLOTHS, SUMMARY} from "./routes"
import Intro from "./components/Intro";
import 'bootstrap/dist/css/bootstrap.min.css';
import Cloths from "./components/Cloths";
import Food from "./components/Food";
import Summary from "./components/Summary";

type Page = {
    name: string,
    data?: any
}

export interface SceneProps {
    page: Page,
    lang: string

    changePage(page: Page): void,

    changeLang(lang): void
}


const App: React.FC = () => {
    const [lang, changeLang] = useState("pl")
    const [page, changePage] = useState({
        name: INTRO,
        data: null
    })

    let CurrentPage;

    console.log(page.name)
    console.log(page.name === CLOTHS)
    if (page.name === INTRO) {
        CurrentPage = Intro
    } else if (page.name === CLOTHS) {
        CurrentPage = Cloths
    }else if (page.name === FOOD) {
        CurrentPage = Food
    }else if (page.name === SUMMARY) {
        CurrentPage = Summary
    }
    console.log(CurrentPage);

    return (
        <CurrentPage changeLang={changeLang} changePage={changePage} lang={lang} page={page}/>
    );
}

export default App;
