import React, {useContext, useState} from 'react';
import styled from 'styled-components'
import LogoImg from "../assets/plansza_1/logo.png"
import LABELS from "../labels";
import {SceneProps} from "../App"
import {CLOTHS, FOOD} from "../routes";

const Logo = styled.img`
    margin-top: 20%;
    z-index: 10;
`

const BtnIMG = styled.img`
`

const HeaderVideo = styled.video`
    z-index: 0;
`

const MenuDiv = styled.div`
    margin-top: 95%;
    z-index: 10;
    position: absolute !important;
`

const LangMenu = styled.div`
    margin-top: 150%;
    z-index: 10;
    position: absolute !important;
`

const LangImg = styled.img`
    width: 25%;
`

const Intro: React.FC<SceneProps> = ({changeLang, changePage, lang}) => {
    const langSwitch = () => {
        changeLang(LABELS[lang]["lang"]["name"])
    }

    return (
        <div className="scene">
            <Logo className="center-abs" src={LogoImg}/>
            <HeaderVideo className="full-screen" src={LABELS[lang]["header"]} muted loop autoPlay/>
            <MenuDiv className={"col-12 text-center "}>
                <BtnIMG src={LABELS[lang]["food"]} className={"mx-auto p-4"} onClick={() => changePage({name: FOOD})}/>
                <BtnIMG src={LABELS[lang]["cloths"]} className={"mx-auto p-4"}
                        onClick={() => changePage({name: CLOTHS})}/>
            </MenuDiv>

            <LangMenu className={"text-center col-12"}>
                <LangImg src={LABELS[lang]["lang"]["icon"]} className={"mx-auto p-4"} onClick={langSwitch}/>
            </LangMenu>
        </div>
    )
}

export default Intro;