import Header_PL from "./assets/plansza_1/pl/header.webm"
import Header_ENG from "./assets/plansza_1/eng/header.webm"
import Back from "./assets/plansza_1/header_animation.webm"
import Food_PL from "./assets/plansza_1/pl/food.png"
import Cloths_PL from "./assets/plansza_1/pl/cloths.png"
import Food_ENG from "./assets/plansza_1/eng/food.png"
import Cloths_ENG from "./assets/plansza_1/eng/cloths.png"
import Lang_ENG from "./assets/plansza_1/eng.png"
import Lang_PL from "./assets/plansza_1/pl.png"
import Cash_PL from "./assets/plansza_2/pl/cash.png"
import Cash_ENG from "./assets/plansza_2/eng/cash.png"
import Back_PL from "./assets/plansza_2/pl/back.png"
import Back_ENG from "./assets/plansza_2/eng/back.png"
import ClothHeader_PL from "./assets/plansza_2/pl/header.webm"
import ClothHeader_ENG from "./assets/plansza_2/eng/header.webm"
import FoodHeader_PL from "./assets/plansza_3/pl/header.webm"
import FoodHeader_ENG from "./assets/plansza_3/eng/header.webm"
import BackStart_PL from "./assets/plansza_4/pl/back.png"
import BackStart_ENG from "./assets/plansza_4/eng/back.png"

const LABELS = {
    "pl": {
        "clothHeader": ClothHeader_PL,
        "foodHeader": FoodHeader_PL,
        "backStart": BackStart_PL,
        "product": "Produkt",
        "liters": "Litry",
        "liters2": "Litrów",
        "days": "dni",
        "sum": "Suma",
        "canLive": "Człowiek może funkcjonować XXX dni przy tej ilości zużytej wody",
        "bathtubs": "To równowartość pełnych wanien wody w liczbie:",
        "header": Header_PL,
        "cloths": Cloths_PL,
        "food": Food_PL,
        "lang": {
            "icon": Lang_ENG,
            "name": "eng"
        },
        "cash": Cash_PL,
        "back": Back_PL,
        "facts": {
            "cloths": [
                "Przemysł odzieżowy zajmuje drugie miejsce w rankingu branż najbardziej zanieczyszczających środowisko. Przed nim są tylko koncerny naftowe.",
                "Co roku przy farbowaniu tkanin zużywa się ponad 2 tryliony m³ wody. Ścieki z tego procesu, często nieoczyszczane, trafiają do rzek, a stamtąd do mórz skąd rozprzestrzeniają się na cały świat.",
                "Za każdym razem, kiedy ubranie jest prane, ponad 700 000 mikroskopijnych włókien uwalnia się do oceanów, gdzie mogą być one połknięte przez organizmy żyjące w morzu, stając się częścią łańcucha pokarmowego i potencjalnie skończyć na naszych talerzach.",
                "Pranie odzieży uwalnia rocznie 500 000 ton mikrowłókien do oceanów, co odpowiada 50 miliardom plastikowych butelek. Nawet 30% plastiku oceanicznego może pochodzić z mikrowłókien.",
                "60% wszystkich wyprodukowanych ubrań ląduje na składowiskach odpadów lub jest palone w przeciągu roku od produkcji.",
                "W samym 2016 roku, odpady powstałe podczas produkcji odzieży wynosiły więcej niż 800 000 ton.",
                "W 2015 roku przemysł odzieżowy wyemitował 1,2 miliarda ton dwutlenku węgla – więcej niż wszystkie międzynarodowe loty i żegluga morska razem wzięte.",
                "Cały sektor odzieżowy szacunkowo zużywa co roku około 79 miliardów metrów sześciennych życiodajnego płynu na nawadnianie pól bawełny oraz jej przetwórstwo przemysłowe. Tyle wody wystarczyłoby na zaspokojenie rocznych potrzeb aż 110 milionów ludzi.",
                "Kleje i barwniki, używane w produkcji obuwia, zawierają szkodliwe substancje, takie jak chlorowane fenole, tribromofenol czy sześciowartościowy chrom. Zużyte buty są raczej wyrzucane niż poddawane recyklingowi, kończąc na wysypiskach, gdzie zanieczyszczają tak glebę, jak i wodę.",
                "Na wyprodukowanie koszulki bawełnianej zużywa się średnio 2 700 litrów wody. To tyle, ile człowiek wypija przez dwa i pół roku.",
                "Przeciętny konsument kupił w 2014 roku o 60% więcej ubrań niż w roku 2000, a jednocześnie wyrzucił je po czasie krótszym o połowę.",
                "Jednorazowe pranie zużywa ponad 100 litrów wody i zużywa dużo energii.  Opcja suszenia ubrań w pralce pochłania nawet 5 razy więcej prądu jak samo pranie.",
                "W 2019 roku na wysypisko trafiły ubrania o wadze prawie 83 milionów ton. To wystarczająco aby zapełnić port w australijskim Sydney."
            ],
            "food": [
                "50% wszystkich terenów możliwych do zamieszkania zajmują uprawy rolne",
                "⅓produkowanej na świecie żywności jest marnowana. Szacuje się jej wartość na około biliona dolarów.",
                "Obszar większy od Chin i przy tym 25% światowych zasobów wody słodkiej, są wykorzystywane do produkcji żywności, która nigdy nie jest spożywana.",
                "Ilość odpadów żywnościowych w Europie to około 100 kilogramów rocznie na jedną osobę. To nawet 10 razy więcej jak w Azji Południowej czy Afryce Subsaharyjskiej.",
                "W krajach uprzemysłowionych nawet 40% odpadów spożywczych powstaje na poziomie detalicznym, ponieważ żywność nie spełnia wysokich standardów kosmetycznych.",
                "Marnowana żywność generuje nawet 3,3 miliarda ton dwutlenku węgla, gazu cieplarnianego, który przyczynia się do zmian klimatu.",
                "Gdyby odpady spożywcze były by państwem, byłyby trzecim największym emiterem gazów cieplarnianych, zaraz za USA i Chinami.",
                "Do 2050 roku szacuje się, że będzie żyło 33% więcej ludzi. Aby zaspokoić zapotrzebowanie na żywność, moglibyśmy zwiększyć produkcję jedzenia o 60-70%, albo przestać marnować produkty spożywcze.",
                "Jedzenie 1 hamburgera tygodniowo przez rok wiąże się z produkcją ponad 600 kilogramów gazów cieplarnianych. To tyle, ile produkuje samochód jadący z Wrocławia do Madrytu.",
                "Więcej niż połowa emitowanych gazów cieplarnianych w przemyśle spożywczym pochodzi z produkcji mięsa.",
                "Aż ¼ emitowanych gazów cieplarnianych pochodzi z produkcji żywności",
                "Bydło hodowane na wylesionych terenach produkuje 12 razy więcej dwutlenku węgla, jak z naturalnych pastwisk.",
                "Statystyczny Polak zjada rocznie około 40 kilogramów wieprzowiny. Wodą potrzebną do produkcji takiej ilości mógł byś wziąć prysznic 3 tysiące razy."
            ]
        }
    },
    "eng": {
        "days": "days",
        "clothHeader": ClothHeader_ENG,
        "foodHeader": FoodHeader_ENG,
        "backStart": BackStart_ENG,
        "product": "Item",
        "liters": "Litres",
        "liters2": "Litres",
        "sum": "Total",
        "canLive": "Single person can live XXX days with this amount of water",
        "bathtubs": "Full bathtubs equivalent is:",
        "header": Header_ENG,
        "cloths": Cloths_ENG,
        "food": Food_ENG,
        "lang": {
            "icon": Lang_PL,
            "name": "pl"
        },
        "cash": Cash_ENG,
        "back": Back_ENG,
        "facts": {
            "cloths": [
                "Clothing sector is on a second place in a most environmentally polluting industries ranking. Higher placed is only oil industry.",
                "Every year, over 2 trillion m³ of water are used for fabric dyeing. Wastewater from this process, often untreated, goes to the rivers, and from there to the seas, then spreads all over the world.",
                "Every time clothes is washed, over 700 000 microscopic fibers are released into the oceans, where they can be swallowed by marine life, becoming part of the food chain and potentially end up on our plates.",
                "Washing clothes releases yearly 500 000 tons of microfibers into the oceans, equivalent to 50 billion plastic bottles. Even 30% of ocean plastic may become from microfibers.",
                "60% of all clothes produced are landfilled or burned within a year of production.",
                "Only in 2016, waste made during clothes production was more than an 800 000 tons.",
                "In 2015, the clothing sector emitted 1,2 billion tons of carbon dioxide - more than all international flights and shipping combined.",
                "The entire clothing sector uses around 79 billion cubic meters of life-giving fluid every year for irrigating and processing cotton fields. That amount of water would be enough to meet the annual needs of 110 million people.",
                "Adhesives and dyes used in the manufacture of footwear contain harmful substances such as chlorinated phenols, tribromophenol and hexavalent chromium. Used shoes are thrown away rather than recycled, ending up in landfills where they pollute both soil and water.",
                "It takes about 2,700 liters of water to produce one t-shirt. This is enough for one person to drink for two and a half year.",
                "In 2014, the average consumer bought 60% more clothes than in 2000, and at the same time discarded them after half the time.",
                "One-time laundry uses over 100 liters of water and consumes a lot of energy. The option of drying clothes in the washing machine consumes up to 5 times more electricity than washing only.",
                "In 2019 up to 83 milion tons of clothes went to the landfill. It’s enough to fill up the harbor in Sydney, Australia."
            ],
            "food": [
                "⅓ of the food produced in the world is wasted. It is estimated at around one trillion dollars",
                "In industrialized countries, up to 40% of food waste is generated at the retail level, because food does not meet high cosmetic standards.",
                "The area larger than China and 25% of the world's fresh water resources are used to produce food that is never consumed.",
                "The amount of food waste in Europe is about 100 kilos per year per person. It's even 10 times more than in South Asia or sub-Saharan Africa",
                "50% of all habitable areas are cultivated",
                "Food waste generates up to 3,3 billion tons of carbon dioxide, a greenhouse gas that contributes to climate change",
                "If food waste would be a country, it would be the 3rd largest greenhouse gas emitter, just after the US and China.",
                "By 2050, it is estimated that 33% more people will live. To meet the demand for food, we could increase food production by 60-70%, or stop wasting food.",
                "Eating one hamburger a week for a year is associated with the production of over 600 kilograms of greenhouse gases. This is as much as the car going from Wroclaw to Madrid.",
                "More than half of the greenhouse gas emitted in the food industry comes from meat production.",
                "As much as ¼ of greenhouse gas emissions come from food production",
                "Cattle reared in deforested areas produce 12 times more carbon dioxide than natural pastures.",
                "An average Pole eats about 40 kilos of pork annually. With the water needed to produce this amount, you could take a shower three thousand times."
            ]
        }
    },
    "back": Back
}

export default LABELS