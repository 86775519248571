import React from "react"
import RecipStart from "../assets/plansza_2/recipt_start.png";
import RecipEnd from "../assets/plansza_2/recipt_end.png";
import styled from "styled-components";
import LABELS from "../labels";

const Recip = styled.div`
    background-color: none;
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 188px;
    top: 160px;
`

const RecipBody = styled.div`
    background-color: white;
    margin: 0 0 0 0;
    width: 100%;
    padding: 0 0 0 0;
`

const NameRow = styled.td`
  width": "45%;
`
const LitersRow = styled.td`
  word-break: break-all;
  width": "55%;
`

const RecipPrinter = ({picked, waterSum, lang}) => {
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const formatWater = (num) => {
        const strNum = num.toString()

        if (strNum.length > 3) {
            return `${strNum.substring(0, strNum.length - 3)} ${strNum.substring(strNum.length - 3)}`
        } else {
            return strNum
        }
    }

    return (
        <div className={"col-5 text-center"}>
            <img className={"mx-auto"} src={RecipStart} style={{"marginTop": "35%"}}/>
            <Recip className={"mx-auto"}>
                <RecipBody className={"p-3"}>
                    <table className="table table-hover text-left" style={{"fontSize": "60%"}}>
                        <thead>
                        <tr>
                            <th scope="col">{LABELS[lang]["product"]}</th>
                            <th scope="col">{LABELS[lang]["liters"]}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(picked).map((key) => {
                            return (
                                <tr key={key} style={{"borderBottom": "2px solid black"}}>
                                    <NameRow>{capitalizeFirstLetter(picked[key].label[lang])}</NameRow>
                                    <LitersRow>{formatWater(picked[key].water)} L</LitersRow>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    <div className={"offset-sm-4 col-8 text-center pt-3"}>
                        <p><strong>{LABELS[lang]["sum"]}</strong></p>
                        <p><strong>{formatWater(waterSum)} L</strong></p>
                    </div>
                </RecipBody>
                <img className={"mx-auto"} src={RecipEnd} style={{"width": "100%", "marginTop": "-20px"}}/>
            </Recip>
        </div>
    )
}

export default RecipPrinter